import { useEffect } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSessionData } from '@confluence/session-data';
import {
	fetchCollabPermissionToken,
	nativeCollabPermissionsTokenState,
} from '@confluence/native-collab';

/*
 * The NativeEditPageDataLoader component allows for queries/components to be loaded on edit button hover.
 * Currently it fetches the NCS permissions token.
 */
export const NativeEditPageDataLoader = ({ contentId, spaceKey, contentType }) => {
	const { userId, cloudId } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		void (async () => {
			const getPermissionsToken = async (): Promise<string | null> => {
				try {
					const token = await fetchCollabPermissionToken(contentId, '')();
					if (token) {
						nativeCollabPermissionsTokenState.setTimestamp(contentId);
						return token;
					}
				} catch (err: any) {
					createAnalyticsEvent({
						type: 'sendOperationalEvent',
						data: {
							actionSubject: 'NCS preloading',
							action: 'error preloading perms token on hover',
							source: 'NativeEditPageDataLoader',
							attributes: {
								contentId,
								err,
							},
						},
					}).fire();
				}
				return null;
			};

			nativeCollabPermissionsTokenState.setToken(contentId, getPermissionsToken());
		})();
	}, [contentId, cloudId, contentType, spaceKey, userId, createAnalyticsEvent]);

	return null;
};
