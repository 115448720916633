import type { FC } from 'react';
import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type {
	EditorTypeAndVersionPropertyQueryType,
	EditorTypeAndVersionPropertyQueryVariablesType,
} from '@confluence/load-edit-page';
import {
	EditorTypeAndVersionPropertyQuery,
	extractEditorContentType,
	extractIsEditorVersionFabric,
	NativeEditPageDataLoader,
} from '@confluence/load-edit-page';
import type { IEditorPreloader } from '@confluence/edit-button';
import {
	isJSOnlyPreloadEditSources,
	isLoadingFromCreate,
} from '@confluence/load-edit-page/entry-points/checkEditSourceType';
import { GetCollabServiceWithMigrationQuery } from '@confluence/native-collab';
import { useEditSource } from '@confluence/load-edit-page/entry-points/useEditSource';

import { nativeEditorComponentsPreloader, tinyEditorComponentsPreloader } from './lib';
import { TinyEditPageDataLoaderLoader } from './components/TinyEditPageDataLoader';

/**
 * Preloads editor components and data. Uses feature flags + embedded editor property + {@link GetCollabServiceWithMigrationQuery} to
 * determine if the content is backed by "Native" collab service or Synchrony.
 * See also {@see EditPageLoadingComponent}
 */
const Preloader: FC<IEditorPreloader> = ({ contentId, spaceKey, isEmbeddedEditor }) => {
	const preloadTimingStart = performance.now();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [{ editSource }] = useEditSource();
	const isCreate = isLoadingFromCreate(editSource);
	const isEditPreloadJSOnly = isJSOnlyPreloadEditSources(editSource);

	const skipEditorTypeAndVersionQuery = isEmbeddedEditor || isCreate || !contentId;

	const {
		data: isEditorVersionFabricData,
		loading: isEditorVersionFabricLoading,
		error: isEditorVersionFabricError,
	} = useQuery<
		EditorTypeAndVersionPropertyQueryType,
		EditorTypeAndVersionPropertyQueryVariablesType
	>(EditorTypeAndVersionPropertyQuery, {
		variables: {
			contentId,
		},
		skip: skipEditorTypeAndVersionQuery,
	});

	// Finished takes into account if query is done loading or if it was skipped.
	const editorTypeAndVersionQueryFinished =
		skipEditorTypeAndVersionQuery || !isEditorVersionFabricLoading;

	let isFabricEditor: boolean = true;
	let contentType: string | null = null;

	// Skip extracting isFabricEditor + default to Fabric editor if query was skipped or is loading.
	if (!skipEditorTypeAndVersionQuery && !isEditorVersionFabricLoading) {
		isFabricEditor =
			!!isEditorVersionFabricError || extractIsEditorVersionFabric(isEditorVersionFabricData);
		contentType = !!isEditorVersionFabricError
			? contentType
			: extractEditorContentType(isEditorVersionFabricData);
	}

	const fireTimingAnalytics = () => {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				actionSubject: 'editor',
				action: 'preloader.timing',
				source: 'editorPreloader',
				attributes: {
					timing: performance.now() - preloadTimingStart,
					collabService: 'ncs',
					isFabricEditor,
					contentId,
				},
			},
		}).fire();
	};

	useEffect(() => {
		// internally pubsub-client will only initialize once
		void import(
			/* webpackChunkName: "loadable-confluencepubsub-client" */ '@confluence/pubsub-client'
		).then(({ getPubSubClient }) => getPubSubClient());

		if (editorTypeAndVersionQueryFinished) {
			if (!isFabricEditor) {
				void tinyEditorComponentsPreloader().then(() => {
					fireTimingAnalytics();
				});
			} else {
				void nativeEditorComponentsPreloader().then(() => {
					fireTimingAnalytics();
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditorVersionFabricData, isFabricEditor]);

	if (isCreate || !contentId || isEditPreloadJSOnly) {
		// Don't preload editor data when it is create or contentId is not available
		return null;
	}

	if (editorTypeAndVersionQueryFinished && !isFabricEditor) {
		return <TinyEditPageDataLoaderLoader contentId={contentId} contentType={contentType} />;
	} else if (editorTypeAndVersionQueryFinished) {
		// NativeEditPageDataLoader is NCS specific (loads NCS queries only)
		return (
			<NativeEditPageDataLoader
				contentId={contentId}
				spaceKey={spaceKey}
				contentType={contentType}
			/>
		);
	} else {
		return null;
	}
};

/**
 * Preloads editor resources and data when user intent to navigate
 * to the edit page is already known.
 *
 * @param contentId - content Id
 * @param spaceKey - space key
 * @param editSource - where this preload request is from
 */
export const getEditorPreloader = () => {
	return (config: IEditorPreloader) => {
		return <Preloader {...config} />;
	};
};
