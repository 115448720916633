import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';

import { getApolloClient } from '@confluence/graphql';

import type {
	EditorTypeAndVersionPropertyQuery as EditorTypeAndVersionPropertyQueryType,
	EditorTypeAndVersionPropertyQueryVariables as EditorTypeAndVersionPropertyQueryVariablesType,
} from './preload/queries/__types__/EditorTypeAndVersionPropertyQuery';
import { EditorTypeAndVersionPropertyQuery } from './preload/queries/EditorTypeAndVersionPropertyQuery.graphql';

export type FetchEditorTypeAndVersionPropertyArgs = {
	contentId: string;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
};

const firePerformanceEvent = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{ queryLoadTime, contentId }: { queryLoadTime: number; contentId: string },
) => {
	createAnalyticsEvent({
		type: 'sendOperationalEvent',
		data: {
			action: 'fetchEditorTypeAndVersionPropertyQuery',
			actionSubject: 'nativeEditor',
			source: 'confluenceFrontend',
			objectId: contentId,
			attributes: {
				queryLoadTime,
			},
		},
	}).fire();
};

/**
 * Calls and returns results from EditorTypeAndVersionPropertyQuery as well as firing relevant performance event.
 * @return EditorTypeAndVersionPropertyQueryType or undefined if the query errored
 */
export const fetchEditorTypeAndVersionPropertyQuery = async ({
	contentId,
	createAnalyticsEvent,
}: FetchEditorTypeAndVersionPropertyArgs): Promise<
	EditorTypeAndVersionPropertyQueryType | undefined
> => {
	const apolloClient = getApolloClient();
	const beforeQueryTime = performance.now();
	const editorTypeAndVersionPropertyResult = await apolloClient.query<
		EditorTypeAndVersionPropertyQueryType,
		EditorTypeAndVersionPropertyQueryVariablesType
	>({
		query: EditorTypeAndVersionPropertyQuery,
		variables: {
			contentId,
		},
		fetchPolicy: 'cache-first',
		context: { single: 'true' },
	});
	if (createAnalyticsEvent)
		firePerformanceEvent(createAnalyticsEvent, {
			queryLoadTime: performance.now() - beforeQueryTime,
			contentId,
		});

	return editorTypeAndVersionPropertyResult?.data;
};

/**
 * Helper function to extract version property from data object received from fetchEditorTypeAndVersionPropertyQuery
 * @return boolean
 */
export const extractIsEditorVersionFabric = (
	data?: EditorTypeAndVersionPropertyQueryType,
): boolean => {
	const value = data?.content?.nodes?.[0]?.editor?.nodes?.[0]?.value;
	return value === '"v2"' || value === 'v2';
};

/**
 * Helper function to extract type property from data object received from fetchEditorTypeAndVersionPropertyQuery
 * @return string | null
 */
export const extractEditorContentType = (
	data?: EditorTypeAndVersionPropertyQueryType,
): string | null => {
	return data?.content?.nodes?.[0]?.type || null;
};
