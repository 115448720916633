import gql from 'graphql-tag';

export const EditorTypeAndVersionPropertyQuery = gql`
	query EditorTypeAndVersionPropertyQuery($contentId: ID) {
		content(id: $contentId, status: ["current", "draft"]) {
			nodes {
				id
				type
				editor: properties(key: "editor") {
					nodes {
						id
						key
						value
					}
				}
			}
		}
	}
`;
